<template>
    <div class="trade" style="height:100%">
        <Top name="我的订单" back email isHas="Account"></Top>
        <div class="trade_w" style="height: calc(100% - 84px);">
            <van-tabs class="trade_t" v-model="active" @click="tabsClick">
                <!-- <van-tab title="全部" to="/trade"></van-tab> -->
                <!-- <van-tab title="我的出售" to="/trade/saletrade"></van-tab>"{ name: 'news', params: { userId: 1111}}" -->
                <van-tab title="我的购买" to="/trade"></van-tab>
                 <van-tab title="我的出售"></van-tab>
            </van-tabs>
            <!-- this.$router.push({
                name: 'characterDetails',
                query: {
                    channel_id:this.formDate.channel_id,
                    goods_id: goods_id,
                }
            }); -->
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height:100%;overflow-y: auto;">
                <router-view v-if="isRouterAlive"/>
            </van-pull-refresh>
            
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Trade',
    components: {
        Top,
    },
    data() {
        return {
            isLoading:false,
            active: 0,
            isRouterAlive:true
        }
    },
    created(){
    },
    methods: {
        onRefresh(){
            // console.log(222)
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
            setTimeout(()=>{
                this.isLoading = false
            },500)
            
            // this.isLoading = false
        },
        //顶部tab切换
        tabsClick(val) {
            console.log(val);
            // console.log(val)
            if(val==1){
                this.$router.push({
                    path: '/trade/saletrade',
                    query: {
                        type:1
                    }
                })
            }
        },
    },
    watch: {
        $route: {
            immediate: true, //一旦监听到路由的变化立即执行
            handler(to, from) {
                //console.log(to, from);
                switch(to.name) {
                    case 'Smptiontrade':
                        this.active = 0;
                        break;
                    case 'Saletrade':
                        this.active = 1;
                        break;
                    // default:
                    //     this.active = 2;
                }
            }
        }
    }
}
</script>
